
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลใบอนุญาตสถานตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                  ข้อมูลใบอนุญาตสถานตรวจสภาพรถ
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <button @click="getsh()" class="btn btn-primary"><i class="fa fa-search"></i> ค้นหา</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model search -->
              <div class="modal fade" id="model-sh" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ค้นหาข้อมูลเพิ่มเติม</b>
                            <button type="button" @click="closeModalsh()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col"> 
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >ที่อยู่(จังหวัด)</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="searchcountyarea_value" :searchable="true" v-model="searchcounty" :custom-label="searchcountyarea_select" placeholder="เลือกจังหวัด" />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >ประเภทของสถานตรวจสภาพ</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="status_check_value" :searchable="true" v-model="status_check" :custom-label="status_check_select" placeholder="เลือกประเภทของสถานตรวจสภาพ" />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >วันที่ได้รับใบอนุญาต</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdatefrom" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label" >วันที่สิ้นอายุใบอนุญาต</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdateto" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >สถานะของใบอนุญาตจัดตั้ง</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="status2_check_value" :searchable="true" v-model="status_check2" :custom-label="status_check_select2" placeholder="เลือกสถานะของใบอนุญาตจัดตั้ง" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-primary" role="alert">
                            <p><b>หมายเหตุ</b></p>
                            <p>- ในการค้นหาสามารถเลือกค้นหาข้อมูลอย่างใดอย่างหนึ่งได้</p>
                        </div>
                            
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="search()" class="btn btn-primary">ค้นหาข้อมูล</button>
                            <button type="button" @click="closeModalsh()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>ลำดับ</th>
                                <th>ชื่อสถานตรวจสภาพรถ</th>		
                                <th>เลขที่ใบอนุญาตจัดตั้ง</th>
                                <th>ชื่อผู้ได้รับอนุญาตจัดตั้ง</th>
                                <th>ที่ตั้งสถานตรวจสภาพรถ(จังหวัด)</th>
                                <th class="none">หมายเลขบัตรประชาชน</th>
                                <th>ประเภทสถานตรวจสภาพรถ</th>													
                                <th class="none">วันที่ได้รับอนุญาต</th>													
                                <th>วันสิ้นสุดอายุ</th>																																					
                                <th class="none">เบอร์โทรศัพท์</th>		
                                <th>สถานะ</th>	
                                <th class="none">ภาพถ่ายสำนักงาน</th>	
                                <th class="none">ภาพถ่ายสถานตรวจสภาพ</th>																							
                                <th class="none">ไฟล์ PDF ที่แนบมา</th>
                              </tr>
                            </thead>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_sh: null,
})
function openModalsh(){ state.modal_sh.show()}
function closeModalsh(){state.modal_sh.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const searchdatefrom = ref('');
const searchdateto = ref('');
const searchcounty = ref('');
const status_check = ref('');
const status_check2 = ref('');
const dataselect = ref();

const status_check_value = ref([{	value: '1', name: 'รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภทและทุกขนาดน้ำหนัก' },{ value: '2', name: 'รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม และรถจักรยานต์' },{ value:'3', name:'รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์'}])
function status_check_select({name}) {return `${name}`}
const status2_check_value = ref([
  {	value: '0', name: 'โดนระงับ' },
  { value: '1', name: 'ปกติ' },
  { value: '2', name: 'ถูกลงโทษ'},
  { value: '3', name: 'สิ้นสุดใบอนุญาต'},
  { value: '4', name: 'เลิกกิจการ'},
  { value: '5', name: 'เพิกถอนใบอนุญาต'},
  { value: '6', name: 'เพิกถอนชื่อ'},
])
function status_check_select2({name}) {return `${name}`}
const searchcountyarea_value = ref([{id:'',names:''}]);
function searchcountyarea_select({names}) {return `${names}`}

async function getsh(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`getdatash`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      cid: storeAuth.county_id
    });
    if(res.data.status == 1){
      searchcountyarea_value.value = res.data.datas.datactarea;
      Swal.close()
      openModalsh()
    }
}


onMounted(() => {
  getdata_table();
  state.modal_sh = new Modal('#model-sh', {});
})

function statusOwner(id) {
  if(id == 0){ 
    return 'โดนระงับ';
  }else if(id == 1){ 
    return 'ปกติ';
  }else if(id == 2){ 
    return 'ถูกลงโทษ';
  }else if(id == 3){ 
    return 'สิ้นสุดใบอนุญาต';
  }else if(id == 4){ 
    return 'เลิกกิจการ';
  }else if(id == 5){ 
    return 'เพิกถอนใบอนุญาต';
  }else if(id == 5){
    return 'เพิกถอนชื่อ';
  }
}

function trotype(id) {
  if(id == 1){ 
    return 'รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภทและทุกขนาดน้ำหนัก';
  }else if(id == 2){ 
    return 'รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม และรถจักรยานต์';
  }else if(id == 3){ 
    return 'รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์';
  }
}

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`inspectionsystemaddowner`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([
        res.data.datas[i].id,
        res.data.datas[i].nameaddresscar,
        res.data.datas[i].license_number,
        res.data.datas[i].license_name,
        res.data.datas[i].names,
        res.data.datas[i].idcard,
        trotype(res.data.datas[i].car_type),
        new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),
        new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),
        res.data.datas[i].tel,
        statusOwner(res.data.datas[i].status),
        res.data.datas[i].img_office,
        res.data.datas[i].img_addresscar,
        res.data.datas[i].file_other
      ]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'DESC']],
  language: dataTextTH,
  columnDefs: [
        { width: '8%', targets: 0 },
        { width: '10%', targets: 2 },
        { width: '10%', targets: 4 },
    ]
});

async function search(){

  if(!searchdatefrom.value && !searchdateto.value && !searchcounty.value && !status_check.value && !status_check2.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณเลือกหรือกรอกข้อมูลที่ต้องการค้นหาเพิ่มเติมก่อน",
      icon: "warning"
    });
  }else{

    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let searchdatefroms = '';
    let searchdatetos = '';
    if(searchdatefrom.value){
      const days = searchdatefrom.value.getDate() <= 9?'0'+searchdatefrom.value.getDate():searchdatefrom.value.getDate();
      const months = searchdatefrom.value.getMonth() + 1;
      const years = searchdatefrom.value.getFullYear();
      searchdatefroms = `${years}-${months <= 9?'0'+months:months}-${days}`;
    }
    if(searchdateto.value){
      const dayt = searchdateto.value.getDate() <= 9?'0'+searchdateto.value.getDate():searchdateto.value.getDate();
      const montht = searchdateto.value.getMonth() + 1;
      const yeart = searchdateto.value.getFullYear();
      searchdatetos = `${yeart}-${montht <= 9?'0'+montht:montht}-${dayt}`;
    }

    const res = await axios.post(`inspectionsystemaddownersh`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      s1: searchdatefroms,
      s2: searchdatetos,
      s3: searchcounty.value.id,
      s4: status_check.value.value,
      s5: status_check2.value.value,
    });
    if(res.data.status == 1){
      datatable.value = [];
      for (let i = 0; i < res.data.datas.length; i++) {
        datatable.value.push([
          res.data.datas[i].id,
          res.data.datas[i].nameaddresscar,
          res.data.datas[i].license_number,
          res.data.datas[i].license_name,
          res.data.datas[i].names,
          res.data.datas[i].idcard,
          trotype(res.data.datas[i].car_type),
          new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),
          new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),
          res.data.datas[i].tel,
          statusOwner(res.data.datas[i].status),
          res.data.datas[i].img_office,
          res.data.datas[i].img_addresscar,
          res.data.datas[i].file_other
        ])
      }
      Swal.close()
      closeModalsh()
    }
  }
}

</script>