<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="@/assets/images/dlt/profile/user-admin.png" alt="" />
      <div class="media-body">
        <span>{{ usertype == 4?nameaddresscar:fname+' '+lname }}</span>
        <p class="mb-0 font-roboto">
          เจ้าหน้าที่ สขจ./สขพ. <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click="logout()">
        <vue-feather type="log-in"></vue-feather><span>ออกจากระบบ</span>
      </li>
    </ul>
  </li>
</template>

<script>
import { storeAuth } from "../../store/modules/auth";

export default {
  name: 'Profile',
  data () {
      return {
        nameaddresscar: '',
        fname: '',
        lname: '',
        usertype: '',
      };
    },
  methods: {
    logout: function () {
      localStorage.clear();
      // this.$router.replace('/auth/login');
      window.location.href = '/';
    }
    // },
  },
  created() {
    this.nameaddresscar = storeAuth.nameaddresscar;
    this.fname = storeAuth.first_name;
    this.lname = storeAuth.last_name;
    this.usertype = storeAuth.group_id;
  },
};
</script>
