
<template>
    <div class="container-fluid">
      <Breadcrumbs main="ดาวน์โหลดสถิติการส่งรายงานการตรวจตรวจเข้า MDM"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body" >
                <form class="needs-validation" novalidate="" action="" method="POST">
                  <div class="row">
                    <div class="form-group row col-sm-12 mb-2">
                        <label class="col-sm-5 col-form-label text-end" >วันที่</label>
                        <div class="col-sm-5">
                            <VueDatePicker locale="th" v-model="date_start" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-5 col-form-label text-end" >จังหวัดที่กำกับดูแล</label>
                        <div class="col-sm-5">
                            <multiselect :options="searchcounty_area_value" :searchable="true" v-model="searchcounty" :custom-label="searchcounty_area_select" placeholder="เลือกจังหวัด" />
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                        <button class="btn btn-pill btn-primary btn-air-primary"  @click="statistics" type="button">ค้นหาสถิติ</button>
                    </div>
                  </div>
                  <br>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="datastatus == 1" class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="col-md-12 text-center">
                    <button class="btn btn-pill btn-primary btn-air-primary me-2" @click="excelExport('xlsx')">บันทึกเป็นไฟล์ Excel</button>
                    <button class="btn btn-pill btn-primary btn-air-primary" @click="printDiv()" >ปริ้น</button>
                </div>
              </div>
              <div class="card-body" >
                <form class="needs-validation" novalidate="" action="" method="POST">
                  <div class="row">
                        <div id="printableTable" class="col-sm-12 text-center table-responsive">
                            <table class="table" ref="tbdata">
                                <thead>
                                <tr>
                                    <th colspan="2"><h4>สถิติของวันที่ {{ countdata.date_check }}</h4></th>
                                </tr>
                                <tr>
                                    <th>รายละเอียด</th>
                                    <th>จำนวน(รายการ/คัน)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>ข้อมูลการตรวจสภาพทั้งหมด</td>
                                    <td>{{ countdata.totalcheck }}</td>
                                </tr>
                                <tr>
                                    <td>ผลตรวจที่ผ่านจำนวน</td>
                                    <td>{{ countdata.checksuccess }}</td>
                                </tr>
                                <tr>
                                    <td>ผลตรวจที่ไม่ผ่านจำนวน</td>
                                    <td>{{ countdata.checknosuccess }}</td>
                                </tr>
                                <tr>
                                    <td>ส่งเข้าระบบ MDM จำนวนทั้งหมด</td>
                                    <td>{{ countdata.totalsend }}</td>
                                </tr>
                                <tr>
                                    <td>เข้าระบบ MDM แล้วจำนวน</td>
                                    <td>{{ (countdata.sendsuccess + countdata.mdmagin) }}</td>
                                </tr>
                                <tr>
                                    <td>ไม่เข้าระบบ MDM จำนวน</td>
                                    <td>{{ ((countdata.nosend - countdata.checknosuccess) - countdata.mdmagin)}}</td>
                                </tr>
                                <tr>
                                    <td>ไม่ส่งข้อมูล(ผลการตรวจสภาพไม่ผ่าน) จำนวน</td>
                                    <td>{{ countdata.checknosuccess }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                  <br>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import axios from "../../axios";
import { storeAuth } from "../../store/modules/auth";
import * as XLSX from 'xlsx'

export default {
  data(){
      return{
          searchcounty:'',
          date_start:'',
          countdata:{
              checksuccess:'',
              checknosuccess:'',
              sendsuccess:'',
              nosend:'',
              mdmagin:'',
              totalcheck:'',
              totalsend:'',
              date_check:'',
            },
            datastatus: 0,
            searchcounty_area_value:[],
          }
      }, 
  methods:{
      getdata(){
        if(this.searchcounty_area_value.length <= 0)
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()

        axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          cid: storeAuth.county_id,
        }).then(res=>{
            this.searchcounty_area_value = res.data.datas.datactarea;
            this.$swal.close();
          }).catch(err=>{
            console.log(err)
            this.$swal.close();
        })
      },
      searchcounty_area_select({names}) {
        return `${names}`
      },
      statistics(){
          if(!this.date_start){
              this.$swal({
                title:'คำเตือน', 
                text:'กรุณาเลือกวันที่ ที่ต้องกาค้นหาก่อนกดปุ่ม "ค้นหาสถิติ"',
                icon:'warning', 
              }) 
          }else{
                let data = {
                          date_start:this.date_start,
                          searchcounty:this.searchcounty,
                      }
              this.getcount(data);
          }
      },
      async getcount(data){
        this.datastatus = 0;
        this.countdata = {};
        this.$swal({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.$swal.showLoading()
          
        await axios.post(`download`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          cid: storeAuth.county_id,
          dt:13,
          datas: data
        }).then(rs=>{
            this.$swal.close();
            this.datastatus = rs.data.status;
            this.countdata = rs.data.datas;
            console.log('--->',rs.data);
          }).catch(err=>{
            this.$swal.close();
            console.log(err)
        })
      },
      excelExport(type){
        var data = this.$refs.tbdata;
        var excelFile = XLSX.utils.table_to_book(data, {sheet: "sheet1"});
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'รายละเอียดผลการตรวจสภาพตามกฏหมาว่าด้วยรถยนต์(แบบละเอียด).' + type);
      },
      async printDiv() {
        await this.$htmlToPaper('printableTable');
      }
  }, 
  computed:{}, 
  watch: {}, 
  components:{}, 
  beforeCreate(){}, 
  created(){
    this.getdata()
  }, 
  beforeMount(){}, 
  mounted(){}, 
  beforeUpdate(){}, 
  updated(){}, 
  beforeDestroy(){}, 
  destroyed(){}, 
}
</script>