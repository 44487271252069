
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลการตรวจรถตามกฎหมายรถขนส่ง"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    ข้อมูลจะแสดง 100 รายการล่าสุดหากต้องข้อมูลเพิ่มเติมสามารถค้นหาได้จากปุ่มค้นหา
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <button @click="getsh()" class="btn btn-primary"><i class="fa fa-search"></i> ค้นหา</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model search -->
              <div class="modal fade" id="model-sh" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">ค้นหาข้อมูลเพิ่มเติม</b>
                            <button type="button" @click="closeModalsh()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col"> 
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >วันที่ส่งข้อมูลเข้าระบบ</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdatefrom" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label" >ถึงวันที่</label>
                                    <div class="col-sm-8">
                                        <VueDatePicker locale="th" v-model="searchdateto" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                                    </div>
                                </div>
                                
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >อักษรเลขทะเบียน</label>
                                    <div class="col-sm-8">
                                      <input v-model='searchid' class="form-control" type="text" maxlength="30">
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >หมายเลขเลขทะเบียน</label>
                                    <div class="col-sm-8">
                                    <input v-model='searchnum' class="form-control" type="text" maxlength="4">
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >จังหวัด</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="searchcounty_value" :searchable="true" v-model="searchcounty" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <hr>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >สถานะการตรวจ</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="status_check_value" :searchable="true" v-model="status_check" :custom-label="status_check_select" placeholder="เลือกสถานะการตรวจ" />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >ชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="tro_name_id_value" :searchable="true" v-model="tro_name_id" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                                    </div>
                                </div>
                                  <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >ชื่อผู้ควบคุมตรวจสภาพ</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="user_check_value" :searchable="true" v-model="user_check_1" :custom-label="user_check_select" placeholder="เลือกชื่อผู้ควบคุมตรวจสภาพ" />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <label class="col-sm-4 form-label text-right" >ชื่อเจ้าหน้าที่ตรวจสภาพ</label>
                                    <div class="col-sm-8">
                                      <multiselect :options="user_check_value" :searchable="true" v-model="user_check_2" :custom-label="user_check_select" placeholder="เลือกชื่อเจ้าหน้าที่ตรวจสภาพ" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-primary" role="alert">
                            <p><b>หมายเหตุ</b></p>
                            <p>- ในการค้นหาสามารถกรอกอ้างใดอ้างหนึ่งก็ได้ยกเว้นวันที่ส่งข้อมูลเข้าระบบ หากต้องการค้นหาวันช่วงวันที่ต้องเลือกวันที่ทั้ง 2 ช่อง</p>
                        </div>
                            
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="search()" class="btn btn-primary">ค้นหาข้อมูล</button>
                            <button type="button" @click="closeModalsh()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                    <th>ลำดับการตรวจ</th>
                                    <th>เลขทะเบียน</th>
                                    <th>ผลการตรวจ</th>
                                    <th>จำนวนครั้งที่ตรวจ</th> 
                                    <th>เวลาที่ส่งรายงาน</th>
                                    <th>เลขที่ใบอนุญาต</th>
                                    <th>ชื่อ สถานตรวจสภาพรถ</th>
                                    <th>รูปภาพ</th>  
                                    <th>จัดการ</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_sh: null,
})
function openModalsh(){ state.modal_sh.show()}
function closeModalsh(){state.modal_sh.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const searchdatefrom = ref('');
const searchdateto = ref('');
const searchid = ref('');
const searchnum = ref('');
const searchcounty = ref('');
const status_check = ref('');
const tro_name_id = ref('');
const user_check_1 = ref('');
const user_check_2 = ref('');
const status_check_sent = ref('');
const dataselect = ref();

const status_check_value = ref([{	value: '1', name: 'ผ่าน' },{ value: '2', name: 'ไม่ผ่าน' }])
function status_check_select({name}) {return `${name}`}
const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}
const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}
const user_check_value = ref([]);
function user_check_select({first_name,last_name}) {return `${first_name} ${last_name}`}

async function getsh(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`getdatash`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      cid: storeAuth.county_id
    });
    if(res.data.status == 1){
      searchcounty_value.value = res.data.datas.datact;
      tro_name_id_value.value = res.data.datas.datag4;
      user_check_value.value = res.data.datas.datag5;
      Swal.close()
      openModalsh()
    }else{
      Swal.close()
    }
}


onMounted(() => {
  getdata_table();
  state.modal_sh = new Modal('#model-sh', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`inspectionreporttruck`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([
        res.data.datas[i].ID,res.data.datas[i].CAR_LIC_ID_CHAR + ' ' + res.data.datas[i].CAR_LIC_ID_NUM + ' ' +res.data.datas[i].names,res.data.datas[i].STATUS_CHECK == 1?"ผ่าน":'ไม่ผ่าน',res.data.datas[i].CHECK_NUMBER,new Date(res.data.datas[i].SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].TRO_ID,res.data.datas[i].TRO_NAME,
      `<a href="${storeAuth.UrlImagesTruck}${res.data.datas[i].PIC_CAR}" target="_blank"><img src="${storeAuth.UrlImagesTruck}${res.data.datas[i].PIC_CAR}" width="58"></a>`
    ]);
    }
    Swal.close()
  }else{
    Swal.close()
  }
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'DESC']],
  language: dataTextTH,
  columnDefs: [
        // { width: '12%', targets: 0 },
        { width: '18%', targets: 1 },
        {
            data: null,
            render: function (data) {
              return '<div class="btn-group"><a href="/user/datatruckdetail/'+ data[0] +'" target="_blank" class="btn btn-success"><i class="fa fa-search"></i></a><a href="/user/datatruckpdf/'+ data[0] +'" target="_blank" class="btn btn-primary" data-bs-original-title="" title=""><i class="fa fa-print"></i></a></div>';
            },
            targets: -1
        },
    ]
});

async function search(){

  if(!searchdatefrom.value && !searchdateto.value && !searchid.value && !searchnum.value && !searchcounty.value && !status_check.value && !tro_name_id.value && !user_check_1.value && !user_check_2.value && !status_check_sent.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณเลือกหรือกรอกข้อมูลที่ต้องการค้นหาเพิ่มเติมก่อน",
      icon: "warning"
    });
  }else if(!searchdatefrom.value && searchdateto.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกวันที่เพิ่มข้อมูล(เริ่มต้น)ด้วย",
      icon: "warning"
    });
  }else{

    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let searchdatefroms = '';
    let searchdatetos = '';
    if(searchdatefrom.value){
      const days = searchdatefrom.value.getDate() <= 9?'0'+searchdatefrom.value.getDate():searchdatefrom.value.getDate();
      const months = searchdatefrom.value.getMonth() + 1;
      const years = searchdatefrom.value.getFullYear();
      searchdatefroms = `${years}-${months <= 9?'0'+months:months}-${days}`;
    }
    if(searchdateto.value){
      const dayt = searchdateto.value.getDate() <= 9?'0'+searchdateto.value.getDate():searchdateto.value.getDate();
      const montht = searchdateto.value.getMonth() + 1;
      const yeart = searchdateto.value.getFullYear();
      searchdatetos = `${yeart}-${montht <= 9?'0'+montht:montht}-${dayt}`;
    }

    const res = await axios.post(`inspectionreporttrucksh`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      s1: searchdatefroms,
      s2: searchdatetos,
      s3: searchid.value,
      s4: searchnum.value,
      s5: searchcounty.value.id,
      s6: status_check.value.value,
      s7: tro_name_id.value.tro_inspectioncenter_id,
      s8: user_check_1.value.inspector_num,
      s9: user_check_2.value.inspector_num,
    });
    if(res.data.status == 1){
      datatable.value = [];
      for (let i = 0; i < res.data.datas.length; i++) {
        datatable.value.push([
          res.data.datas[i].ID,res.data.datas[i].CAR_LIC_ID_CHAR + ' ' + res.data.datas[i].CAR_LIC_ID_NUM + ' ' +res.data.datas[i].names,res.data.datas[i].STATUS_CHECK == 1?"ผ่าน":'ไม่ผ่าน',res.data.datas[i].CHECK_NUMBER,new Date(res.data.datas[i].SEND_DATETIME).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].TRO_ID,res.data.datas[i].TRO_NAME,
          `<a href="${storeAuth.UrlImagesTruck}${res.data.datas[i].PIC_CAR}" target="_blank"><img src="${storeAuth.UrlImagesTruck}${res.data.datas[i].PIC_CAR}" width="58"></a>`
        ]);
      }
      Swal.close()
      closeModalsh()
    }
  }
}

</script>