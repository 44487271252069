
<template>

    <div class="container-fluid">
        <Breadcrumbs main="รายการตรวจสอบผู้ตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    สามารถ แก้ไข,ลบข้อมูล ได้ในหน้านี้
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">แก้ไขข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >ผู้ควบคุมการตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <input class="form-control" type="text" :value="name" readonly>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >การพิจารณาโทษ</label>
                                <div class="col-sm-8">
                                  <select class="form-select" v-model="punish">
                                    <option value="">ยังไม่มีการพิจารณาโทษ</option>
                                    <option value="1">ตักเตือน</option>
                                    <option value="2">ระงับการตรวจสภาพ</option>
                                </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >สถานะ</label>
                                <div class="col-sm-8">
                                  <select class="form-select" v-model="status">
                                    <option value="1">อยู่</option>
                                    <option value="0">ไม่อยู่</option>
                                  </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >การดำเนินการตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <select class="form-select" v-model="actuation">
                                    <option value="1">การอำนวยความสะดวกให้เจ้าหน้าที่ในการตรวจสอบ</option>
                                    <option value="2">การแสดงบัตรประจำตัวของผู้ปฏิบัติงาน</option>
                                    <option value="3">การแต่งกาย ความประพฤติ กริยา มารยาทของผู้ปฏิบัติงาน</option>
                                    <option value="4">การจัดส่งรายงานการตรวจสภาพรถตามระยะเวลา</option>
                                    <option value="5">แนวเส้นกำหนดพื้นที่ต่าง ๆ มีความชัดเจน</option>
                                    <option value="6">ความสะอาด เรียบร้อยอาคารสถานที่</option>
                                    <option value="7">การบำรุงรักษาเครื่องตรวจสภาพรถ</option>
                                    <option value="8">การเตรียมความพร้อมใช้่งานของเครื่องตรวจสภาพรถ</option>
                                </select>
                                </div>
                              </div>
                              <div class="mb-2 row">
                                <label class="col-sm-4 form-label text-right" >การวินิฉัยตรวจสภาพรถ</label>
                                <div class="col-sm-8">
                                  <select class="form-select" v-model="diagnosis">
                                    <option value="1">ถูกต้อง</option>
                                    <option value="0">บกพร่อง</option>
                                </select>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                                <tr>
                                  <th>หมายเลขอ้างอิง</th>
                                  <th>หมายเลขการตรวจสอบสถานตรวจสภาพ</th>
                                  <th>ชื่อ นามสกุล</th>
                                  <th>ตรวจเมื่อ</th>
                                  <th>การพิจารณาโทษ</th>
                                  <th>การดำเนินการตรวจสภาพรถ</th>
                                  <th>การวินิฉัยตรวจสภาพรถ</th>
                                  <th>จัดการ</th>
                                </tr>
                            </thead>
                            <template #column-7="props">
                              <div class="btn-group">
                                <button @click="geteditdata(props.cellData,props.rowData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_edit: null,
})
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const name = ref('');
const punish = ref('');
const status = ref('');
const actuation = ref('');
const diagnosis = ref('');
const dataeditid = ref('');

onMounted(() => {
  getdata_table();
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  
  const res = await axios.post(`inspection`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 10,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      let punish = '';
      if(res.data.datas[i].punish == ''){
        punish = 'ยังไม่มีการพิจารณาโทษ';
      }else if(res.data.datas[i].punish == 1){
        punish = 'ตักเตือน';
      }else{
        punish = 'ระงับการตรวจสภาพ';
      }
      let actuation = '';
      if(res.data.datas[i].actuation == 1){
        actuation = 'การอำนวยความสะดวกให้เจ้าหน้าที่ในการตรวจสอบ';
      }else if(res.data.datas[i].actuation == 2){
        actuation = 'การแสดงบัตรประจำตัวของผู้ปฏิบัติงาน';
      }else if(res.data.datas[i].actuation == 3){
        actuation = 'การแต่งกาย ความประพฤติ กริยา มารยาทของผู้ปฏิบัติงาน';
      }else if(res.data.datas[i].actuation == 4){
        actuation = 'การจัดส่งรายงานการตรวจสภาพรถตามระยะเวลา';
      }else if(res.data.datas[i].actuation == 5){
        actuation = 'แนวเส้นกำหนดพื้นที่ต่าง ๆ มีความชัดเจน';
      }else if(res.data.datas[i].actuation == 6){
        actuation = 'ความสะอาด เรียบร้อยอาคารสถานที่';
      }else if(res.data.datas[i].actuation == 7){
        actuation = 'การบำรุงรักษาเครื่องตรวจสภาพรถ';
      }else if(res.data.datas[i].actuation == 8){
        actuation = 'การเตรียมความพร้อมใช้่งานของเครื่องตรวจสภาพรถ';
      }else{
        actuation = '';
      }
      let diagnosis = ''
      if(res.data.datas[i].diagnosis == '1'){
        diagnosis = 'ถูกต้อง';
      }else if(res.data.datas[i].diagnosis == '0'){
        diagnosis = 'บกพร่อง';
      }else{
        diagnosis = '';
      }
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].examination_id,res.data.datas[i].first_name + ' ' + res.data.datas[i].last_name,new Date(res.data.datas[i].date_check).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),punish,actuation,diagnosis,res.data.datas[i].id]);
    }
    Swal.close()
  }else{
    Swal.close()
  }
}

async function geteditdata(id) {
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`inspection`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 6,
    datas: [id],
  });
  if(res.data.status == 1){
    console.log(res.data.datas[0])
      dataeditid.value = id;
      name.value = res.data.datas[0].first_name + ' ' + res.data.datas[0].last_name;
      punish.value = res.data.datas[0].punish;
      status.value = res.data.datas[0].status;
      actuation.value = res.data.datas[0].actuation;
      diagnosis.value = res.data.datas[0].diagnosis;
      Swal.close();
      openModaledit()
  }else{
      Swal.close();
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ดึงข้อมูลไม่สำเร็จ",
        icon: "error"
      });
    }
}

async function editdata() {
  console.log(diagnosis.value)
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`inspection`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 7,
    datas: [dataeditid.value,punish.value,status.value,actuation.value,diagnosis.value],
  });
  if(res.data.status == 1){
    datatable.value = []
    getdata_table();
    Swal.close();
    closeModaledit()
    Swal.fire({
      title: "สำเร็จ",
      text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
      icon: "success"
    }); 
  }else{
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "แก้ไขข้อมูลไม่สำเร็จ",
      icon: "error"
    }); 
  }

}

function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 12,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '10%', targets: 0 },
        { width: '10%', targets: 1 },
    ]
});



</script>