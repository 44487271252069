<template>
    <div>
  
      <div class="container-fluid">
        <div class="row ">
          <div class="col-12 p-0">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                    <img class="img-fluid for-dark" src="../../assets/images/dlt/logo/dlt_login.png" alt="looginpage" />
                  </a>
                </div>
                <div class="login-main">
                  <div class="theme-form">
                    <h4 class="text-center">ระบบรายงานผลการตรวจสภาพรถผ่านระบบสารสนเทศ (สขจ./สขพ.)</h4>
                    <p class="text-center">กรุณากรอก ชื่อผู้ใช้ และ รหัสผ่าน เพื่อเข้าสู่ระบบ</p>
                    <div class="form-group">
                      <label class="col-form-label">ชื่อผู้ใช้</label>
                      <input class="form-control" type="text" v-model="user" placeholder="กรุณากรอกชื่อผู้ใช้" >
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">รหัสผ่าน</label>
                      <div class="form-input position-relative">
                        <input class="form-control" type="password" v-model="pass" placeholder="กรุณากรอกรหัสผ่าน" >
                        <div class="show-hide"><span class="show"> </span></div>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <!-- <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox">
                        <label class="text-muted" for="checkbox1">คงอยู่ในระบบตลอด</label>
                      </div> -->
                      <!-- <router-link class="link" to="/"> ลืมรหัสผ่าน</router-link> -->
                      <div class="text-end mt-3">
                        <button class="btn btn-primary btn-block w-100 btn-lg" type="button" @click="login">เข้าสู่ระบบ</button>
                      </div>
                    </div>
                    
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
  import { ref,inject } from 'vue'

  import { useRouter } from 'vue-router'
  const router = useRouter()

  import axios from "../../axios";

  import Storage from 'vue-ls';

  const options = {
    namespace: '', 
    name: 'ls',
    storage: 'local',
  };

  const { ls } = Storage.useStorage(options)

  const Swal = inject('$swal')

  let user = ref('');
  let pass = ref('');

  async function login(){

    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    
    if(!user.value){
      Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอก ชื่อผู้ใช้งาน",
        icon: "warning"
      });
    }else if(!pass.value){
      Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอก รหัสผ่าน",
        icon: "warning"
      });
    }else{
      try {
          const res = await axios.post("loginfront",{
              username : user.value,
              password : pass.value
            },
          );
          if(res.data.status == 1){
            // localStorage.setItem('v6', res.data.datas.v6)
            ls.set('v6', res.data.datas.v6,10 * 60 * 60 * 1000)
            Swal.fire({
              title: "เข้าสู่ระบบสำเร็จ",
              text: "กดปุ่ม ตกลง เพื่อไปยังหน้าแดชบอร์ด",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ตกลง"
            }).then(() => {
              router.push({ path: '/user/dashboard' })
            });
          }else if(res.data.status == 2){
            Swal.fire({
              title: "ไม่สำเร็จ",
              text: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
              icon: "error"
            });
          }else{
            Swal.fire({
              title: "ไม่สำเร็จ",
              text: "เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่",
              icon: "error"
            });
          }
        }catch(err){
          Swal.fire({
              title: "ไม่สำเร็จ",
              text: "เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่",
              icon: "error"
            });
          // console.log(err);
        }
    }
  }
  </script>
  