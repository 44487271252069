
<template>

    <div class="container-fluid">
        <Breadcrumbs main="ข้อมูลสถานตรวจสภาพรถ"/>

        <div class="row project-cards">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="mb-2 row">
                        <div class="col-md-6 d-flex">
                            <ul class="nav nav-tabs border-tab" role="tablist">
                                <li class="nav-item">
                                    สามารถเพิ่ม,แก้ไข,ลบข้อมูล ได้ในหน้านี้
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <button @click="getadd()" class="btn btn-primary"><i class="fa fa-search"></i> เพิ่มข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <!-- model data log -->
              <div class="modal fade" id="model-add" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">เพิ่มข้อมูล</b>
                            <button type="button" @click="closeModaladd()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >Username</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="username">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >Password</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="password" v-model="password">
                                  </div>
                                </div>
                      
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ชื่อสถานตรวจสภาพรถ</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="nameaddresscar">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ประเภทรถที่ได้รับอนุญาต</label>
                                  <div class="col-sm-9">
                                      <div class="form-group m-t-15 custom-radio-ml">
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="car_type" value="1">รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภท<br>และทุกขนาดน้ำหนัก</label>
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="car_type" value="2">รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม<br>และรถจักรยานต์</label>
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="car_type" value="3">รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์</label>
                                      </div>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เลขที่ใบอนุญาต</label>
                                  <div class="col-sm-9">
                                    <multiselect :options="licen_name_id_value" :searchable="true" v-model="licenseid" :custom-label="licen_name_id_select" placeholder="เลือกเลขที่ใบอนุญาต" />
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ที่อยู่ของสถานตรวจสภาพรถ</label>
                                  <div class="col-sm-9">
                                    <textarea class="form-control" rows="3" v-model="addresscar"></textarea>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >จังหวัด/เขต(ที่กำกับดูแล)</label>
                                  <div class="col-sm-9" >
                                    <multiselect :options="searchcounty_value" :searchable="true" v-model="county_id" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" @select="onSelectCounty"/>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >อำเภอ <b class="text-warning">(ต้องเลือกจังหวัดก่อน)</b></label>
                                  <div class="col-sm-9" >
                                    <multiselect :options="district_name_value" :searchable="true" v-model="districts_id" :custom-label="district_name_select" placeholder="เลือกอำเภอ"/>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >รหัสไปรษณีย์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="zipcode">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เบอร์โทรศัพท์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="tel">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เบอร์โทรสาร</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="fax">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >สถานะ</label>
                                  <div class="col-sm-9" >
                                      <select class="form-select" v-model="status">
                                        <option value="0">ระงับการใช้งาน </option>
                                        <option value="1">ปกติ </option>
                                        <option value="3">สิ้นสุดใบอนุญาต </option>
                                        <option value="4">เลิกกิจการ </option>
                                        <option value="5">เพิกถอนใบอนุญาต </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >อีเมล์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="email">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ละติจูด</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="lat">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ลองติจูด</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="lon">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ภาพถ่ายสำนักงาน <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64img1"  accept="image/*">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ภาพถ่ายสถานตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64img2" accept="image/*">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >รายละเอียดเพิ่มเติม</label>
                                  <div class="col-sm-9">
                                    <textarea class="form-control" rows="3" v-model="history"></textarea>
                                  </div>
                                </div>
                                <div class="mb-2 row" >
                                  <label class="col-sm-3 col-form-label">วันปฏิบัติงาน เวลา</label>
                                  <div class="col-sm-9">
                                    <div class="table-responsive">
                                      <table class="table table-bordered checkbox-td-width">
                                          <thead>
                                              <tr>
                                                  <td>วัน</td>
                                                  <td>เวลาเริ่มทำงาน</td>
                                                  <td>เวลาสิ้นสุดการทำงาน</td>
                                              </tr>
                                          </thead>
                                          <tbody>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d1" true-value="1" false-value="">จันทร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d1_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d1_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d2" true-value="1" false-value="">อังคาร
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d2_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d2_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d3" true-value="1" false-value="">พุธ
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d3_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d3_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d4" true-value="1" false-value="">พฤหัสบดี
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d4_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d4_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d5" true-value="1" false-value="">ศุกร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d5_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d5_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d6" true-value="1" false-value="">เสาร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d6_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d6_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="working_d7" true-value="1" false-value="">อาทิตย์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d7_in" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="working_d7_out" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                      </div>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ข้อมูลหรือเอกสารอื่นๆ ตามที่กรมการขนส่งทางบกกำหนดรูปแบบไฟล์ pdf <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64pdf" accept="application/pdf">
                                  </div>
                                </div>

                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="adddata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaladd()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model data log -->
              <!-- model search -->
              <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">แก้ไขข้อมูล</b>
                            <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >Username</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[0]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >Password</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="password" v-model="dataedit[1]">
                                  </div>
                                </div>
                      
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ชื่อสถานตรวจสภาพรถ</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[2]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ประเภทรถที่ได้รับอนุญาต</label>
                                  <div class="col-sm-9">
                                      <div class="form-group m-t-15 custom-radio-ml">
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="dataedit[3]" value="1">รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภท<br>และทุกขนาดน้ำหนัก</label>
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="dataedit[3]" value="2">รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม<br>และรถจักรยานต์</label>
                                        <label class="d-block"><input class="radio_animated" type="radio"  v-model="dataedit[3]" value="3">รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์</label>
                                      </div>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เลขที่ใบอนุญาต</label>
                                  <div class="col-sm-9">
                                    <multiselect :options="licen_name_id_value" :searchable="true" v-model="dataedit[4]" :custom-label="licen_name_id_select" placeholder="เลือกเลขที่ใบอนุญาต" />
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ที่อยู่ของสถานตรวจสภาพรถ</label>
                                  <div class="col-sm-9">
                                    <textarea class="form-control" rows="3" v-model="dataedit[5]"></textarea>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >จังหวัด/เขต(ที่กำกับดูแล)</label>
                                  <div class="col-sm-9" >
                                    <multiselect :options="searchcounty_value" :searchable="true" v-model="dataedit[6]" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" @select="onSelectCountyEdit"/>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >อำเภอ <b class="text-warning">(ต้องเลือกจังหวัดก่อน)</b></label>
                                  <div class="col-sm-9" >
                                    <multiselect :options="district_name_value" :searchable="true" v-model="dataedit[7]" :custom-label="district_name_select" placeholder="เลือกอำเภอ"/>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >รหัสไปรษณีย์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[8]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เบอร์โทรศัพท์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[9]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >เบอร์โทรสาร</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[10]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                <label class="col-sm-3 col-form-label" >สถานะ</label>
                                  <div class="col-sm-9" >
                                      <select class="form-select" v-model="dataedit[11]">
                                        <option value="0">ระงับการใช้งาน </option>
                                        <option value="1">ปกติ </option>
                                        <option value="3">สิ้นสุดใบอนุญาต </option>
                                        <option value="4">เลิกกิจการ </option>
                                        <option value="5">เพิกถอนใบอนุญาต </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >อีเมล์</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[12]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ละติจูด</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[13]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ลองติจูด</label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="text" v-model="dataedit[14]">
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ภาพถ่ายสำนักงาน <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64img1"  accept="image/*">
                                    <a v-if="dataedit[4]" class="mt-1" :href="storeAuth.UrlImagesTroOffice+dataedit[15]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ภาพถ่ายสถานตรวจสภาพรถ <span class="text-warning">(ขนาดไฟล์ไม่เกิน 2 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64img2" accept="image/*">
                                    <a v-if="dataedit[4]" class="mt-1" :href="storeAuth.UrlImagesTro+dataedit[16]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >รายละเอียดเพิ่มเติม</label>
                                  <div class="col-sm-9">
                                    <textarea class="form-control" rows="3" v-model="dataedit[17]"></textarea>
                                  </div>
                                </div>
                                <div class="mb-2 row" >
                                  <label class="col-sm-3 col-form-label">วันปฏิบัติงาน เวลา</label>
                                  <div class="col-sm-9">
                                    <div class="table-responsive">
                                      <table class="table table-bordered checkbox-td-width">
                                          <thead>
                                              <tr>
                                                  <td>วัน</td>
                                                  <td>เวลาเริ่มทำงาน</td>
                                                  <td>เวลาสิ้นสุดการทำงาน</td>
                                              </tr>
                                          </thead>
                                          <tbody>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[18]" true-value="1" false-value="">จันทร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[19]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[20]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[21]" true-value="1" false-value="">อังคาร
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[22]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[23]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[24]" true-value="1" false-value="">พุธ
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[25]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[26]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[27]" true-value="1" false-value="">พฤหัสบดี
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[28]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[29]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[30]" true-value="1" false-value="">ศุกร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[31]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[32]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[33]" true-value="1" false-value="">เสาร์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[34]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[35]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" id="chk-ani" type="checkbox" v-model="dataedit[36]" true-value="1" false-value="">อาทิตย์
                                                  </label>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[37]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div class="input-group">
                                                      <input class="form-control" type="time" v-model="dataedit[38]" value=""><span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>
                                                  </div>
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                      </div>
                                  </div>
                                </div>
                                <div class="mb-2 row">
                                  <label class="col-sm-3 col-form-label" >ข้อมูลหรือเอกสารอื่นๆ ตามที่กรมการขนส่งทางบกกำหนดรูปแบบไฟล์ pdf <span class="text-warning">(ขนาดไฟล์ไม่เกิน 10 MB)</span></label>
                                  <div class="col-sm-9">
                                    <input class="form-control" type="file" @change="B64pdf" accept="application/pdf">
                                    <a v-if="dataedit[39]" class="mt-1" :href="storeAuth.UrlFileTro+dataedit[4]" target="_blank">ลิ้งไฟล์เดิมที่แนบไว้</a>
                                  </div>
                                </div>
                            </div>
                          </div> 
                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                          <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                        </div>
                    </div>
                </div>
              </div>
              <!-- end model search -->
                <div class="card">
                    <div class="card-body">
                        <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                            <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>เลขที่ใบอนุญาต</th>
                                <th>ชื่อสถานตรวจสภาพรถ</th>
                                <th>สถานะ</th>
                                <th class="none">วันที่ได้รับอนุญาต</th>
                                <th>วันสิ้นสุดอายุ</th>
                                <th class="none">ชื่อผู้ใช้งาน</th>
                                <th>อำเภอ / จังหวัด</th>
                                <th class="none">เบอร์โทรศัพท์</th>
                                <th class="none">ภาพถ่ายสำนักงาน</th>
                                <th class="none">ภาพถ่ายสถานตรวจสภาพรถ</th>
                                <th class="none">ข้อมูลหรือเอกสารที่แนบ</th>
                                <th>ข้อมูลสถานที่</th>
                                <th>จัดการ</th>
                              </tr>
                            </thead>
                            <template #column-12="props">
                              <div class="btn-group">
                                <a class="btn btn-info" :class="props.cellData[0]&&props.cellData[1]?'':'disabled'" :href="'https://www.google.com/maps/search/'+props.cellData[0]+','+props.cellData[1]" target="_blank" title=""><i class="fa fa-map-marker"></i></a> 
                              <a class="btn btn-info" :class="props.cellData[0]&&props.cellData[1]?'':'disabled'" :href="'https://www.google.com/maps/dir/?api=1&origin&destination='+props.cellData[0]+','+props.cellData[1]" target="_blank" title=""><i class="fa fa-map"></i></a> 
                              </div>
                            </template>
                            <template #column-13="props">
                              <div class="btn-group">
                                <button @click="geteditdata(props.cellData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </div>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')

const state = reactive({
    modal_add: null,
    modal_edit: null,
    modal_del: null,
})
function openModaladd(){ state.modal_add.show()}
function closeModaladd(){state.modal_add.hide()}
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Regular.ttf',
      bold: 'Sarabun-Medium.ttf',
      italics: 'Sarabun-Italic.ttf',
      bolditalics: 'Sarabun-MediumItalic.ttf'  
    }
  };
  doc.defaultStyle.font = "Sarabun";
  var i = 1;
}

const datatable = ref([]);
const dataadd = ref([]);
const dataedit = ref([]);
const dataeditid = ref('');
const dataedituser = ref('');

const username = ref('');
const password = ref('');
const nameaddresscar = ref('');
const car_type = ref('');
const licenseid = ref('');
const addresscar = ref('');
const county_id = ref('');
const districts_id = ref('')
const zipcode = ref('');
const tel = ref('');
const fax = ref('');
const status = ref('1');
const email = ref('');
const lat = ref('');
const lon = ref('');
const img_office = ref('');
const img_addresscar = ref('');
const history = ref('');
const working_d1 = ref(''); const working_d1_in = ref(''); const working_d1_out = ref('');
const working_d2 = ref(''); const working_d2_in = ref(''); const working_d2_out = ref('');
const working_d3 = ref(''); const working_d3_in = ref(''); const working_d3_out = ref('');
const working_d4 = ref(''); const working_d4_in = ref(''); const working_d4_out = ref('');
const working_d5 = ref(''); const working_d5_in = ref(''); const working_d5_out = ref('');
const working_d6 = ref(''); const working_d6_in = ref(''); const working_d6_out = ref('');
const working_d7 = ref(''); const working_d7_in = ref(''); const working_d7_out = ref('');
const file_other = ref('');


const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}
const district_name_value = ref([{district_name:'เลือกจังหวัดก่อน'}]);
function district_name_select({district_name}) {return `${district_name}`}
const licen_name_id_value = ref([]);
function licen_name_id_select({license_number,license_name}) {return `${license_number} / ${license_name}`}

onMounted(() => {
  getdata_table();
  state.modal_add = new Modal('#model-add', {});
  state.modal_edit = new Modal('#model-edit', {});
})

function  B64img1(event) {
  let input = event.target;
  if (input.files && input.files[0]) {
    if((input.files[0].size/1024/1024).toFixed(2) <= 10){
      let reader = new FileReader();
        reader.onload = (e) => {
        img_office.value = e.target.result;
        // console.log(e.target.result)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

function  B64img2(event) {
  let input = event.target;
  if (input.files && input.files[0]) {
    if((input.files[0].size/1024/1024).toFixed(2) <= 10){
      let reader = new FileReader();
        reader.onload = (e) => {
        img_addresscar.value = e.target.result;
        // console.log(e.target.result)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

function  B64pdf(event) {
  let input = event.target;
  if (input.files && input.files[0]) {
    if((input.files[0].size/1024/1024).toFixed(2) <= 10){
      let reader = new FileReader();
        reader.onload = (e) => {
        file_other.value = e.target.result;
        // console.log(e.target.result)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}

async function getdata_table(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

  const res = await axios.post(`usertro`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    licen_name_id_value.value = res.data.datasUG9;
    for (let i = 0; i < res.data.datas.length; i++) {
      let statusdetail = '';
      if(res.data.datas[i].status == 1){ statusdetail = "ปกติ"; }else if(res.data.datas[i].status == 0){ statusdetail = "ระงับการใช้งาน"; }else if(res.data.datas[i].status == 2){ statusdetail = "ถูกลงโทษ";}else if(res.data.datas[i].status == 3){ statusdetail = "สิ้นสุดใบอนุญาต";}else if(res.data.datas[i].status == 4){ statusdetail = "เลิกกิจการ";}else if(res.data.datas[i].status == 5){ statusdetail = "เพิกถอนใบอนุญาต";}
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].license_number,res.data.datas[i].nameaddresscar,statusdetail,new Date(res.data.datas[i].authorized_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),new Date(res.data.datas[i].exp_date).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, 10).split('-').reverse().join('/'),res.data.datas[i].username,res.data.datas[i].district_name + ' / ' + res.data.datas[i].names,res.data.datas[i].tel,
      res.data.datas[i].img_office?`<a href="${storeAuth.UrlImagesTroOffice}${res.data.datas[i].img_office}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      res.data.datas[i].img_addresscar?`<a href="${storeAuth.UrlImagesTro}${res.data.datas[i].img_addresscar}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      res.data.datas[i].file_other?`<a href="${storeAuth.UrlFileTro}${res.data.datas[i].file_other}" target="_blank">คลิกเพื่อดูไฟล์</a>`:`ไม่มีไฟล์ที่แนบ`,
      [res.data.datas[i].lat,res.data.datas[i].lon],
      res.data.datas[i].id
    ]);
    }
    Swal.close();
  }else{
    Swal.close()
  }
}

async function onSelectCounty(){
    if(!county_id.value.id){
      Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกจังหวัดก่อน",
        icon: "error"
      }); 
    }else{
      district_name_value.value = [];
      const res = await axios.post(`usertro`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 5,
        datas: [county_id.value.id]
      });
      if(res.data.status == 1){
        districts_id.value = '';
        district_name_value.value = res.data.datas;
      }
    }
}

async function onSelectCountyEdit(){
    if(!dataedit.value[6].id){
      Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกจังหวัดก่อน",
        icon: "error"
      }); 
    }else{
      district_name_value.value = [];
      const res = await axios.post(`usertro`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 5,
        datas: [dataedit.value[6].id]
      });
      if(res.data.status == 1){
        dataedit.value[7] = '';
        district_name_value.value = res.data.datas;
      }
    }
}

async function getadd(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    if(searchcounty_value.value.length > 1){
      Swal.close()
      openModaladd()
    }else{
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        cid: storeAuth.county_id
      });
      if(res.data.status == 1){
        searchcounty_value.value = res.data.datas.datactarea;
        Swal.close()
        openModaladd()
      }
    }
}

async function adddata() {
  if(!username.value || !password.value || !car_type.value || !licenseid.value || !county_id.value){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const res = await axios.post(`usertro`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 3,
      datas: [
              username.value,
              password.value,
              nameaddresscar.value,
              car_type.value,
              licenseid.value,
              addresscar.value,
              county_id.value,
              districts_id.value,
              zipcode.value,
              tel.value,
              fax.value,
              status.value,
              email.value,
              lat.value,
              lon.value,
              img_office.value,
              img_addresscar.value,
              history.value,
              working_d1.value, working_d1_in.value, working_d1_out.value,
              working_d2.value, working_d2_in.value, working_d2_out.value,
              working_d3.value, working_d3_in.value, working_d3_out.value,
              working_d4.value, working_d4_in.value, working_d4_out.value,
              working_d5.value, working_d5_in.value, working_d5_out.value,
              working_d6.value, working_d6_in.value, working_d6_out.value,
              working_d7.value, working_d7_in.value, working_d7_out.value,
              file_other.value
            ]
    });
    if(res.data.status == 1){
      username.value = '';
      password.value = '';
      nameaddresscar.value = '';
      car_type.value = '';
      licenseid.value = '';
      addresscar.value = '';
      county_id.value = '';
      districts_id.value = '';
      zipcode.value = '';
      tel.value = '';
      fax.value = '';
      status.value = '';
      email.value = '';
      lat.value = '';
      lon.value = '';
      img_office.value = '';
      img_addresscar.value = '';
      history.value = '';
      working_d1.value = ''; working_d1_in.value = ''; working_d1_out.value = '';
      working_d2.value = ''; working_d2_in.value = ''; working_d2_out.value = '';
      working_d3.value = ''; working_d3_in.value = ''; working_d3_out.value = '';
      working_d4.value = ''; working_d4_in.value = ''; working_d4_out.value = '';
      working_d5.value = ''; working_d5_in.value = ''; working_d5_out.value = '';
      working_d6.value = ''; working_d6_in.value = ''; working_d6_out.value = '';
      working_d7.value = ''; working_d7_in.value = ''; working_d7_out.value = '';
      file_other.value = '';
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaladd()
      Swal.fire({
        title: "สำเร็จ",
        text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else if(res.data.status == 4){
      Swal.fire({
        title: "คำเตือน",
        text: "ชื่อผู้ใช้งานมีในระบบแล้ว กรุณาเปลี่ยนชื่อผู้ใช้งาน",
        icon: "warning"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เพิ่มข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

async function geteditdata(id) {
  dataedit.value = [];
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const resData = await axios.post(`usertro`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 4,
        datas: [id]
      });
    if(resData.data.status == 1){
      console.log(resData.data.datas[0])
      if(searchcounty_value.value.length > 1){
          dataedit.value = [];
          dataedituser.value = resData.data.datas[0].username;
          dataeditid.value = id;
          dataedit.value[0] = resData.data.datas[0].username;dataedit.value[1] = '';dataedit.value[2] = resData.data.datas[0].nameaddresscar;dataedit.value[3] = resData.data.datas[0].car_type;dataedit.value[4] = {id:resData.data.datas[0].licenseid,license_number : resData.data.datas[0].license_number2,license_name : resData.data.datas[0].license_name2};dataedit.value[5] = resData.data.datas[0].addresscar;dataedit.value[6] = {id:resData.data.datas[0].county_id,names:resData.data.datas[0].names};dataedit.value[7] = {id:resData.data.datas[0].districts_id,district_name:resData.data.datas[0].district_name};dataedit.value[8] = resData.data.datas[0].zipcode;dataedit.value[9] = resData.data.datas[0].tel;
          dataedit.value[10] = resData.data.datas[0].fax;dataedit.value[11] = resData.data.datas[0].status;dataedit.value[12] = resData.data.datas[0].email;dataedit.value[13] = resData.data.datas[0].lat;dataedit.value[14] = resData.data.datas[0].lon;dataedit.value[15] = resData.data.datas[0].img_office;dataedit.value[16] = resData.data.datas[0].img_addresscar;dataedit.value[17] = resData.data.datas[0].history;dataedit.value[18] = resData.data.datas[0].working_d1;dataedit.value[19] = resData.data.datas[0].working_d1_in;
          dataedit.value[20] = resData.data.datas[0].working_d1_out;dataedit.value[21] = resData.data.datas[0].working_d2;dataedit.value[22] = resData.data.datas[0].working_d2_in;dataedit.value[23] = resData.data.datas[0].working_d2_out;dataedit.value[24] = resData.data.datas[0].working_d3;dataedit.value[25] = resData.data.datas[0].working_d3_in;dataedit.value[26] = resData.data.datas[0].working_d3_out;dataedit.value[27] = resData.data.datas[0].working_d4;dataedit.value[28] = resData.data.datas[0].working_d4_in;dataedit.value[29] = resData.data.datas[0].working_d4_out;
          dataedit.value[30] = resData.data.datas[0].working_d5;dataedit.value[31] = resData.data.datas[0].working_d5_in;dataedit.value[32] = resData.data.datas[0].working_d5_out;dataedit.value[33] = resData.data.datas[0].working_d6;dataedit.value[34] = resData.data.datas[0].working_d6_in;dataedit.value[35] = resData.data.datas[0].working_d6_out;dataedit.value[36] = resData.data.datas[0].working_d7;dataedit.value[37] = resData.data.datas[0].working_d7_in;dataedit.value[38] = resData.data.datas[0].working_d7_out;dataedit.value[39] = resData.data.datas[0].file_other;
          Swal.close();
          openModaledit()
      }else{
        const res = await axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          cid: storeAuth.county_id
        });
        if(res.data.status == 1){
          searchcounty_value.value = res.data.datas.datactarea;
          dataedit.value = [];
          dataedituser.value = resData.data.datas[0].username;
          dataeditid.value = id;
          dataedit.value[0] = resData.data.datas[0].username;dataedit.value[1] = '';dataedit.value[2] = resData.data.datas[0].nameaddresscar;dataedit.value[3] = resData.data.datas[0].car_type;dataedit.value[4] = {id:resData.data.datas[0].licenseid,license_number : resData.data.datas[0].license_number2,license_name : resData.data.datas[0].license_name2};dataedit.value[5] = resData.data.datas[0].addresscar;dataedit.value[6] = {id:resData.data.datas[0].county_id,names:resData.data.datas[0].names};dataedit.value[7] = {id:resData.data.datas[0].districts_id,district_name:resData.data.datas[0].district_name};dataedit.value[8] = resData.data.datas[0].zipcode;dataedit.value[9] = resData.data.datas[0].tel;
          dataedit.value[10] = resData.data.datas[0].fax;dataedit.value[11] = resData.data.datas[0].status;dataedit.value[12] = resData.data.datas[0].email;dataedit.value[13] = resData.data.datas[0].lat;dataedit.value[14] = resData.data.datas[0].lon;dataedit.value[15] = resData.data.datas[0].img_office;dataedit.value[16] = resData.data.datas[0].img_addresscar;dataedit.value[17] = resData.data.datas[0].history;dataedit.value[18] = resData.data.datas[0].working_d1;dataedit.value[19] = resData.data.datas[0].working_d1_in;
          dataedit.value[20] = resData.data.datas[0].working_d1_out;dataedit.value[21] = resData.data.datas[0].working_d2;dataedit.value[22] = resData.data.datas[0].working_d2_in;dataedit.value[23] = resData.data.datas[0].working_d2_out;dataedit.value[24] = resData.data.datas[0].working_d3;dataedit.value[25] = resData.data.datas[0].working_d3_in;dataedit.value[26] = resData.data.datas[0].working_d3_out;dataedit.value[27] = resData.data.datas[0].working_d4;dataedit.value[28] = resData.data.datas[0].working_d4_in;dataedit.value[29] = resData.data.datas[0].working_d4_out;
          dataedit.value[30] = resData.data.datas[0].working_d5;dataedit.value[31] = resData.data.datas[0].working_d5_in;dataedit.value[32] = resData.data.datas[0].working_d5_out;dataedit.value[33] = resData.data.datas[0].working_d6;dataedit.value[34] = resData.data.datas[0].working_d6_in;dataedit.value[35] = resData.data.datas[0].working_d6_out;dataedit.value[36] = resData.data.datas[0].working_d7;dataedit.value[37] = resData.data.datas[0].working_d7_in;dataedit.value[38] = resData.data.datas[0].working_d7_out;dataedit.value[39] = resData.data.datas[0].file_other;
          Swal.close();
          openModaledit()
        }
      }
    }
}

async function editdata() {
  if(!dataedit.value[0] || !dataedit.value[2] || !dataedit.value[3] || !dataedit.value[4] || !dataedit.value[5] || !dataedit.value[7]){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "warning"
    }); 
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`usertro`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 2,
      datas: [dataeditid.value,dataedituser.value,
        dataedit.value[0],dataedit.value[1],dataedit.value[2],dataedit.value[3],dataedit.value[4],dataedit.value[5],dataedit.value[6],dataedit.value[7],dataedit.value[8],dataedit.value[9],
        dataedit.value[10],dataedit.value[11],dataedit.value[12],dataedit.value[13],dataedit.value[14],img_office.value,img_addresscar.value,dataedit.value[17],dataedit.value[18],dataedit.value[19],
        dataedit.value[20],dataedit.value[21],dataedit.value[22],dataedit.value[23],dataedit.value[24],dataedit.value[25],dataedit.value[26],dataedit.value[27],dataedit.value[28],dataedit.value[29],
        dataedit.value[30],dataedit.value[31],dataedit.value[32],dataedit.value[33],dataedit.value[34],dataedit.value[35],dataedit.value[36],dataedit.value[37],dataedit.value[38],file_other.value
      ],
    });
    if(res.data.status == 1){
      datatable.value = []
      getdata_table();
      Swal.close();
      closeModaledit()
      Swal.fire({
        title: "สำเร็จ",
        text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else if(res.data.status == 4){
      Swal.fire({
        title: "คำเตือน",
        text: "ชื่อผู้ใช้งานมีในระบบแล้ว กรุณาเปลี่ยนชื่อผู้ใช้งาน",
        icon: "warning"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "แก้ไขข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

function deldata(data) {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลใช่หรือไม่",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()

      const res = await axios.post(`delete`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 6,
        datas: [data],
      });
      if(res.data.status == 1){
        datatable.value = []
        getdata_table();
        Swal.close();
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลเรียบร้อยแล้ว",
          icon: "success"
        }); 
      }else{
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ลบข้อมูลไม่สำเร็จ",
          icon: "error"
        }); 
      }
    }
  });
}

const options = reactive({
  dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {   
          extend: 'copyHtml5',
          text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
          titleAttr: 'PDF',
          customize: function ( doc ) {
            processDoc(doc);
          }
      }
  ],
  pageLength: 10,
  responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
  // select: {
  //       style: 'multi',
  //       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
  //   },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
  order: [[0, 'desc']],
  language: dataTextTH,
  columnDefs: [
        { width: '10%', targets: 0 },
        { width: '10%', targets: 3 },
        { width: '20%', targets: 6 },
        // {
        //     data: null,
        //     render: function (data) {
        //       return '';
        //     },
        //     targets: -1
        // },
    ]
});



</script>