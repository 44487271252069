
<template>

  <div class="container-fluid">
      <Breadcrumbs main="ข่าวประชาสัมพันธ์(ภายใน)"/>

      <div class="row project-cards">
          <div class="col-md-12 project-list">
              <div class="card">
                  <div class="mb-2 row">
                      <div class="col-md-6 d-flex">
                          <ul class="nav nav-tabs border-tab" role="tablist">
                              <li class="nav-item">
                                  สามารถเพิ่ม,แก้ไข,ลบข้อมูล ได้ในหน้านี้
                              </li>
                          </ul>
                      </div>
                      <div class="col-md-6">
                          <button @click="getadd()" class="btn btn-primary"><i class="fa fa-search"></i> เพิ่มข้อมูล</button>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-12">
            <!-- model data log -->
            <div class="modal fade" id="model-add" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                      <div class="modal-header">
                          <b class="text-ptt-26">เพิ่มข้อมูล</b>
                          <button type="button" @click="closeModaladd()" class="btn-close" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col">
                            <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >หัวข้อประกาศ</label>
                              <div class="col-sm-10">
                                <input v-model='dataadd[0]' class="form-control" type="text">
                              </div>
                            </div>
                            <div class="mb-2 row">
                                <label class="col-sm-2 form-label text-right" >จังหวัด</label>
                                <div class="col-sm-10">
                                  <multiselect :options="searchcounty_value" :searchable="true" v-model="dataadd[1]" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                </div>
                              </div>
                            <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >รายละเอียด</label>
                              <div class="col-sm-10">
                                <ckeditor
                                    v-model="dataadd[2]"
                                    :editor="editor"
                                    :config="editorConfig"
                                />
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div class="modal-footer">
                        <button type="button" @click="adddata()" class="btn btn-primary">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModaladd()" class="btn btn-danger" >ปิด</button>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end model data log -->
            <!-- model search -->
            <div class="modal fade" id="model-edit" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                      <div class="modal-header">
                          <b class="text-ptt-26">แก้ไขข้อมูล</b>
                          <button type="button" @click="closeModaledit()" class="btn-close" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col">
                              <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >หัวข้อประกาศ</label>
                              <div class="col-sm-10">
                                <input v-model='dataedit[0]' class="form-control" type="text">
                              </div>
                            </div>
                            <div class="mb-2 row">
                                <label class="col-sm-2 form-label text-right" >จังหวัด</label>
                                <div class="col-sm-10">
                                  <multiselect :options="searchcounty_value" :searchable="true" v-model="dataedit[1]" :custom-label="searchcounty_select" placeholder="เลือกจังหวัด" />
                                </div>
                              </div>
                            <div class="mb-2 row">
                              <label class="col-sm-2 form-label text-right" >รายละเอียด</label>
                              <div class="col-sm-10">
                                <ckeditor
                                    v-model="dataedit[2]"
                                    :editor="editor"
                                    :config="editorConfig"
                                />
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div class="modal-footer">
                        <button type="button" @click="editdata()" class="btn btn-primary">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModaledit()" class="btn btn-danger" >ปิด</button>
                      </div>
                  </div>
              </div>
            </div>
            <!-- end model search -->
              <div class="card">
                  <div class="card-body">
                      <DataTable :data="datatable" :options="options" class="table table-hover" width="100%" ref="dataselect">
                          <thead>
                              <tr>
                                <th>หมายเลขอ้างอิง</th>
                                <th>ผู้ประกาศ</th>
                                <th>หัวข้อ</th>
                                <th>วันที่ประกาศ</th>
                                <th>จังหวัด</th>
                                <th>จัดการ</th>
                              </tr>
                          </thead>
                          <template #column-5="props">
                            <div class="btn-group">
                              <button @click="geteditdata(props.cellData,props.rowData)" type="button" class="btn btn-warning"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                              <button @click="deldata(props.cellData)" type="button" class="btn btn-danger" data-bs-original-title="" title=""><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </div>
                          </template>
                      </DataTable>
                  </div>
              </div>
          </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";
    
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfMake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Responsive.bootstrap(window.bootstrap);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfMake);

import pdfFonts from '../../assets/fonts/sarabun/custom-fonts.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

import { Modal } from "bootstrap";
const Swal = inject('$swal')
 
import { ClassicEditor, SourceEditing, Heading, BlockQuote, Bold, Italic, Font, Link, List, Table, TableToolbar} from 'ckeditor5';
import coreTranslations from 'ckeditor5/translations/th.js';
import 'ckeditor5/ckeditor5.css';

const state = reactive({
  modal_add: null,
  modal_edit: null,
})
function openModaladd(){ state.modal_add.show()}
function closeModaladd(){state.modal_add.hide()}
function openModaledit(){ state.modal_edit.show()}
function closeModaledit(){state.modal_edit.hide()}

function processDoc(doc) {
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Regular.ttf',
    bold: 'Sarabun-Medium.ttf',
    italics: 'Sarabun-Italic.ttf',
    bolditalics: 'Sarabun-MediumItalic.ttf'  
  }
};
doc.defaultStyle.font = "Sarabun";
var i = 1;
}

const datatable = ref([]);
const dataadd = ref(['',[],'']);
const dataedit = ref([]);
const dataeditid = ref('');
const editor = ClassicEditor
const editorConfig = {
                plugins: [ SourceEditing, Heading, BlockQuote, Bold, Italic, Font, Link, List, Table, TableToolbar ],
                toolbar: [ 
                  'sourceEditing',
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'fontSize',
                  'fontFamily',
                  'fontColor',
                  '|',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'insertTable' 
                ],
                // Other configuration options...
                translations: [ coreTranslations ],
                table: {
                  defaultHeadings: { rows: 1, columns: 1 }
                }
            }
const searchcounty_value = ref([{id:'',names:''}]);
function searchcounty_select({names}) {return `${names}`}

onMounted(() => {
  getdata_table();
  state.modal_add = new Modal('#model-add', {});
  state.modal_edit = new Modal('#model-edit', {});
})

async function getdata_table(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`alert`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    cid: storeAuth.county_id,
    tid: 1,
  });
  if(res.data.status == 1){
    for (let i = 0; i < res.data.datas.length; i++) {
      datatable.value.push([res.data.datas[i].id,res.data.datas[i].first_name + " " + res.data.datas[i].last_name,res.data.datas[i].alert_name,new Date(res.data.datas[i].alert_date).toLocaleString("en-GB", { timeZone: "UTC" }).replace( /,/,"" ),res.data.datas[i].names?res.data.datas[i].names:'ทุกจังหวัด',res.data.datas[i].id]);
    }
    Swal.close();
  }else{
    Swal.close();
  }
}

async function getadd(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    if(searchcounty_value.value.length > 1){
      Swal.close()
      openModaladd()
    }else{
      const res = await axios.post(`getdatash`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        cid: storeAuth.county_id,
      });
      if(res.data.status == 1){
        searchcounty_value.value = res.data.datas.datactarea;
        Swal.close()
        openModaladd()
      }
    }
}

async function adddata() {
if(!dataadd.value[0] || !dataadd.value[1] || !dataadd.value[2]){
  Swal.fire({
      title: "คำเตือน",
      text: "กรุณากรอกข้อมูลให้ครบถ้วน",
      icon: "warning"
  }); 
}else{
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  const res = await axios.post(`alert`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 3,
    datas: [storeAuth.id,dataadd.value[0],dataadd.value[1],dataadd.value[2]],
  });
  if(res.data.status == 1){
    dataadd.value = ['',[],'']
    datatable.value = []
    getdata_table();
    Swal.close();
    closeModaladd()
    Swal.fire({
      title: "สำเร็จ",
      text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
      icon: "success"
    }); 
  }else{
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "เพิ่มข้อมูลไม่สำเร็จ",
      icon: "error"
    }); 
  }
}
}

async function geteditdata(id,data) {
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const resdata = await axios.post(`alert`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 4,
      datas: [id],
    });
    if(resdata.data.status == 1){
      if(searchcounty_value.value.length > 1){
          dataeditid.value = ''
          dataedit.value = []
          dataeditid.value = id
          dataedit.value[0] = data[2]
          dataedit.value[1] = {names: data[4]}
          dataedit.value[2] = resdata.data.datas[0].alert_detail
          Swal.close();
          openModaledit()
      }else{
        const res = await axios.post(`getdatash`,{
          uid: storeAuth.id,
          gid: storeAuth.group_id,
          cid: storeAuth.county_id,
        });
        if(res.data.status == 1){
          searchcounty_value.value = res.data.datas.datactarea;
          dataeditid.value = ''
          dataedit.value = []
          dataeditid.value = id
          dataedit.value[0] = data[2]
          dataedit.value[1] = {names: data[4]}
          dataedit.value[2] = resdata.data.datas[0].alert_detail
          Swal.close();
          openModaledit()
        }
      }
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "แก้ไขข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  
}

async function editdata() {
if(!dataedit.value[0] || !dataedit.value[1] || !dataedit.value[2]){
  Swal.fire({
      title: "คำเตือน",
      text: "กรุณากรอกข้อมูลให้ครบถ้วน",
      icon: "warning"
  }); 
}else{
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()

  const res = await axios.post(`alert`,{
    uid: storeAuth.id,
    gid: storeAuth.group_id,
    tid: 2,
    datas: [dataeditid.value,dataedit.value[0],dataedit.value[1],dataedit.value[2]],
  });
  if(res.data.status == 1){
    datatable.value = []
    getdata_table();
    Swal.close();
    closeModaledit()
    Swal.fire({
      title: "สำเร็จ",
      text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
      icon: "success"
    }); 
  }else{
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "แก้ไขข้อมูลไม่สำเร็จ",
      icon: "error"
    }); 
  }
}
}

function deldata(data) {
Swal.fire({
  title: "คำเตือน",
  text: "ต้องการลบข้อมูลใช่หรือไม่",
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "ตกลง",
  cancelButtonText: "ยกเลิก",
}).then(async (result) => {
  if (result.isConfirmed) {
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`delete`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 7,
      datas: [data],
    });
    if(res.data.status == 1){
      datatable.value = []
      getdata_table();
      Swal.close();
      Swal.fire({
        title: "สำเร็จ",
        text: "ลบข้อมูลเรียบร้อยแล้ว",
        icon: "success"
      }); 
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ลบข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
});
}

const options = reactive({
dom: '<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
buttons: [
    {   
        extend: 'copyHtml5',
        text: '<h5><i class="fa fa-files-o f-w-600"></i></h5> <small>คัดลอก</small>',
        titleAttr: 'Copy'
    },
    {
        extend: 'excelHtml5',
        text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ Excel</small>',
        titleAttr: 'Excel'
    },
    {
        extend: 'csvHtml5',
        text: '<h5><i class="fa fa-file-excel-o f-w-600"></i></h5> <small>ไฟล์ CSV</small>',
        titleAttr: 'CSV'
    },
    {
        extend: 'pdfHtml5',
        text: '<h5><i class="fa fa-file-pdf-o f-w-600"></i></h5> <small>ไฟล์ PDF</small>',
        titleAttr: 'PDF',
        customize: function ( doc ) {
          processDoc(doc);
        }
    }
],
pageLength: 10,
responsive: {
    details: {
      renderer: DataTablesLib.Responsive.renderer.listHiddenNodes(),
    },
  },
// select: {
//       style: 'multi',
//       selector: 'td:not(:nth-child(4)):not(:nth-child(8)):not(:nth-child(10))',
//   },
lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "ทั้งหมด"] ],
order: [[0, 'desc']],
language: dataTextTH,
columnDefs: [
      { width: '13%', targets: 0 },
      { width: '40%', targets: 2 },
      // {
      //     data: null,
      //     render: function (data) {
      //       return '';
      //     },
      //     targets: -1
      // },
  ]
});



</script>